<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large> mdi-handshake </v-icon>
        แก้ไขรายการเข้าพบลูกค้า {{ customerMeeting.customer.name }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-row>
                <v-col cols="4" class="py-0">
                  <v-menu
                    v-model="meetingDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="customerMeeting.date"
                        label="วันเข้าพบ"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        :rules="[(v) => !!v || 'Meeting date is required']"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customerMeeting.date"
                      @input="meetingDateMenu = false"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col class="py-0">
                  <v-textarea
                    v-model="customerMeeting.description"
                    color="secondary"
                    name="รายละเอียด"
                    hint="รายละเอียด"
                    label="รายละเอียด"
                    rows="15"
                    :rules="[(v) => !!v || 'Meeting description is required']"
                  ></v-textarea>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col>
                  <TiptapVuetify v-model="customerMeeting.description" :extensions="extensions" placeholder="รายละเอียด" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="save()"
        >
          <v-icon> mdi-content-save </v-icon>save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

moment.locale("th");

export default {
  name: "EditCustomerMeeting",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: {
      type: String,
    },
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Bold,
        Italic,
        Underline,
        Strike,
        Blockquote,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      loading: false,
      customerMeeting: {
        date: null,
        description: null,
        customer: {
          name: null
        }
      },
      meetingDateMenu: false,
    };
  },
  watch: {},
  mounted() {},
  created() {
    // console.log(this.id);
    this.getDataFromAPI();
  },
  methods: {
    getDataFromAPI() {
      this.loading = true;
      axios
        .get("/customer-meeting/" + this.id)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerMeeting = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .put(
            "/customer-meeting/" + this.id,
            this.customerMeeting
          )
          .then((response) => {
            this.editCustomerMeetingResponse = response.data;
            if (this.editCustomerMeetingResponse.status === "success") {
              this.$router.push(
                "/customer-meeting/" +
                  this.editCustomerMeetingResponse.data.id +
                  "/show"
              );
            }
          })
          .catch(function () {});
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.ProseMirror {
  min-height: 150px;
  max-height: 200px;
  overflow-y: auto;
}
</style>
